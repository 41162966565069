<template>
  <div>
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link to="/drainage/emergancy/riskMember">应急队伍</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item><a href="">修改应急队伍</a></a-breadcrumb-item>
    </a-breadcrumb>

    <Padding :size="'large'" />

    <a-form
      :form="form"
      @submit="handleSubmit"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
      :colon="false"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="名称">
            <a-input
              v-decorator="[
                'name',
                {
                  initialValue: detail.name,
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="手机号">
            <a-input
              v-decorator="[
                'mobile',
                {
                  initialValue: detail.mobile,
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="角色">
            <a-input
              v-decorator="[
                'duty',
                {
                  initialValue: detail.duty,
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="是否是负责人">
            <a-radio-group
              v-decorator="[
                'isCharger',
                {
                  initialValue: detail.isCharger,
                  rules: [
                    {
                      required: true,
                      message: '请选择',
                    },
                  ],
                },
              ]"
            >
              <a-radio :value="true"> 是 </a-radio>
              <a-radio :value="false"> 否 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="是否接收短信通知">
            <a-radio-group
              v-decorator="[
                'isNotify',
                {
                  initialValue: detail.isNotify,
                  rules: [
                    {
                      required: true,
                      message: '请选择',
                    },
                  ],
                },
              ]"
            >
              <a-radio :value="true"> 是 </a-radio>
              <a-radio :value="false"> 否 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="上传照片">
            <Upload :list="fileList" @change="onUpload" />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button htmlType="submit" type="primary">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import Upload from "@/components/upload";
import { edit } from "@/api/risk/riskMember";

export default {
  components: { Upload },
  data() {
    return {
      form: this.$form.createForm(this),
      fileList: [],
      detail: {},
    };
  },
  mounted() {
    const { detail } = this.$route.query;
    console.log(detail);
    this.detail = detail;
    this.fileList = detail.photo?.split(",").map((ele, index) => {
      return {
        completePath: ele,
        name: "图片" + (index + 1) + ".png",
      };
    });
  },

  methods: {
    cancel() {
      this.$router.go(-1);
    },
    onUpload(val) {
      this.fileList = val;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let pictures = this.fileList.map((ele) => ele.completePath).join(",");
          edit({ ...values, id: this.detail.id, photo: pictures }).then(() => {
            this.cancel();
          });
        }
      });
    },
  },
};
</script>
