<template>
  <a-space>
    <input type="file"
           ref="upload"
           @change="onChange"
           style="display: none" />
    <a-button type="primary"
              :loading="loading"
              @click="onClick">
      {{ list.length > 0 ? "继续上传" : "上传" }}
    </a-button>
    <span v-for="(item, index) in list"
          :key="item.completePath">
      <a :href="
          item.completePath.indexOf('http') === 0 ? item.completePath : 'http://' + item.completePath
        "
         target="_blank">{{ item.name }}</a>
      <a-icon type="close"
              style="font-size: 12px; margin-left: 4px"
              @click="remove(index)" />
    </span>
  </a-space>
</template>

<script>
import { upload } from "@/api/upload";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onClick() {
      this.$refs.upload.click();
    },
    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      if (files.length === 0) {
        return;
      }
      data.append("files", files[0]);

      this.loading = true;

      upload(data)
        .then((res) => {
          console.log("upload", res);
          if (Array.isArray(res)) {
            this.$emit("change", [...this.list, ...res]);
          }
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },
    remove(index) {
      this.list.splice(index, 1);
      this.$emit("change", this.list);
    },
  },
};
</script>